import { useAuthApiFetch, useAuthBasedFetch } from "~/api/useApi";
import type {
  ShopBalance,
  PsBalance,
  CreateLinkBody,
  ShopStatistics,
  SalesHistory,
  ShopLinks,
  WithdrawalHistory,
} from "~/types/shop";

const URLS = {
  checkShopUrl: "api/v1/shop/check",
  fetchReusableLinksUrl: (page: number, size: number) =>
    `/api/v1/shop/form/list/?page=${page}&size=${size}`,
  fetchOneTimeLinksUrl: (page: number, size: number) =>
    `/api/v1/shop/onetime/claim/list/?page=${page}&size=${size}`,
  fetchSalesHistoryUrl: "/api/v1/shop/claim/list/",
  createLinkUrl: "api/v1/shop/form/",
  fetchBalanceUrl: "/api/v1/shop/withdrawal/",
  fetchPsBalanceUrl: (ps: number) => `/api/v1/shop/withdrawal/?ps=${ps}`,
  fetchStatisticsUrl: (month: number) => {
    return `/api/v1/shop/statistic/?month=${month}`;
  },
  fetchWithdrawalDirectionsUrl: "/api/v1/shop/withdrawal/inputs/",
  switchBalanceUrl: "/api/v1/shop/withdrawal/switch/",
  withdrawFundsUrl: "/api/v1/shop/withdrawal/",
  fetchWithdrawalHistoryUrl: (page: number, size: number) =>
    `api/v1/shop/withdrawal/history/?page=${page}&size=${size}`,
};

export const useShopApi = () => {
  const checkShop = () => {
    return useAuthBasedFetch(URLS.checkShopUrl);
  };

  const fetchReusableLinks = (page: number, size: number) => {
    return useAuthBasedFetch<ShopLinks>(URLS.fetchReusableLinksUrl(page, size));
  };

  const fetchOneTimeLinks = (page: number, size: number) => {
    return useAuthBasedFetch<ShopLinks>(URLS.fetchOneTimeLinksUrl(page, size));
  };

  const fetchSalesHistory = ({
    page,
    size,
    status,
    from_date, // eslint-disable-line camelcase
    to_date, // eslint-disable-line camelcase
    type,
    id,
  }: {
    page: number;
    size: number;
    status?: string;
    from_date?: string;
    to_date?: string;
    type?: string;
    id?: string;
  }) => {
    return useAuthApiFetch<SalesHistory>(URLS.fetchSalesHistoryUrl, {
      query: { page, size, status, from_date, to_date, type, id }, // eslint-disable-line camelcase
    });
  };

  const createNewLink = (body: CreateLinkBody) => {
    return useAuthApiFetch<{
      link: string;
    }>(URLS.createLinkUrl, { method: "POST", body });
  };

  const fetchShopBalance = () => {
    return useAuthBasedFetch<ShopBalance>(URLS.fetchBalanceUrl);
  };

  const fetchPsBalance = (ps: number) => {
    return useAuthApiFetch<PsBalance>(URLS.fetchPsBalanceUrl(ps));
  };

  const fetchStatistics = (month: number) => {
    return useAuthBasedFetch<ShopStatistics>(URLS.fetchStatisticsUrl(month));
  };

  const fetchWithdrawalDirections = () => {
    return useAuthBasedFetch(URLS.fetchWithdrawalDirectionsUrl);
  };

  const checkBalanceAccumulating = () => {
    return useAuthBasedFetch<{ isSavingsBalance: boolean }>(
      URLS.switchBalanceUrl,
    );
  };

  const swithBalanceAccumulating = () => {
    return useAuthApiFetch(URLS.switchBalanceUrl, {
      method: "POST",
    });
  };

  const withdrawFunds = (requisites: {
    ps: number;
    requisites: Record<string, string>;
  }) => {
    return useAuthApiFetch(URLS.withdrawFundsUrl, {
      method: "POST",
      body: requisites,
    });
  };

  const fetchWithdrawalHistory = (page: number, size: number) => {
    return useAuthBasedFetch<WithdrawalHistory>(
      URLS.fetchWithdrawalHistoryUrl(page, size),
    );
  };

  return {
    checkShop,
    fetchSalesHistory,
    fetchReusableLinks,
    fetchOneTimeLinks,
    createNewLink,
    fetchShopBalance,
    fetchPsBalance,
    fetchStatistics,
    fetchWithdrawalDirections,
    checkBalanceAccumulating,
    swithBalanceAccumulating,
    withdrawFunds,
    fetchWithdrawalHistory,
  };
};
